@font-face {
    font-family: 'Crinsom';
    src: url('fonts/crimson/CrimsonText-Regular.eot');
    src: url('fonts/crimson/CrimsonText-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/crimson/CrimsonText-Regular.woff2') format('woff2'),
        url('fonts/crimson/CrimsonText-Regular.woff') format('woff'),
        url('fonts/crimson/CrimsonText-Regular.ttf') format('truetype'),
        url('fonts/crimson/CrimsonText-Regular.svg#CrimsonText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'circularBlack';
    src: url('fonts/circularBlack/CircularStd-Black.eot');
    src: url('fonts/circularBlack/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularBlack/CircularStd-Black.woff2') format('woff2'),
        url('fonts/circularBlack/CircularStd-Black.woff') format('woff'),
        url('fonts/circularBlack/CircularStd-Black.ttf') format('truetype'),
        url('fonts/circularBlack/CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularBlackItalic';
    src: url('fonts/circularBlackItalic/CircularStd-BlackItalic.eot');
    src: url('fonts/circularBlackItalic/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularBlackItalic/CircularStd-BlackItalic.woff2') format('woff2'),
        url('fonts/circularBlackItalic/CircularStd-BlackItalic.woff') format('woff'),
        url('fonts/circularBlackItalic/CircularStd-BlackItalic.ttf') format('truetype'),
        url('fonts/circularBlackItalic/CircularStd-BlackItalic.svg#CircularStd-BlackItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularBoldItalic';
    src: url('fonts/circularBoldItalic/CircularStd-BoldItalic.eot');
    src: url('fonts/circularBoldItalic/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularBoldItalic/CircularStd-BoldItalic.woff2') format('woff2'),
        url('fonts/circularBoldItalic/CircularStd-BoldItalic.woff') format('woff'),
        url('fonts/circularBoldItalic/CircularStd-BoldItalic.ttf') format('truetype'),
        url('fonts/circularBoldItalic/CircularStd-BoldItalic.svg#CircularStd-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularBook';
    src: url('fonts/circularBook/CircularStd-Book.eot');
    src: url('fonts/circularBook/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularBook/CircularStd-Book.woff2') format('woff2'),
        url('fonts/circularBook/CircularStd-Book.woff') format('woff'),
        url('fonts/circularBook/CircularStd-Book.ttf') format('truetype'),
        url('fonts/circularBook/CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularBookItalic';
    src: url('fonts/circularBookItalic/CircularStd-BookItalic.eot');
    src: url('fonts/circularBookItalic/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularBookItalic/CircularStd-BookItalic.woff2') format('woff2'),
        url('fonts/circularBookItalic/CircularStd-BookItalic.woff') format('woff'),
        url('fonts/circularBookItalic/CircularStd-BookItalic.ttf') format('truetype'),
        url('fonts/circularBookItalic/CircularStd-BookItalic.svg#CircularBookItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularMedium';
    src: url('fonts/circularMedium/CircularStd-Medium.eot');
    src: url('fonts/circularMedium/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularMedium/CircularStd-Medium.woff2') format('woff2'),
        url('fonts/circularMedium/CircularStd-Medium.woff') format('woff'),
        url('fonts/circularMedium/CircularStd-Medium.ttf') format('truetype'),
        url('fonts/circularMedium/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'circularMediumItalic';
    src: url('fonts/circularMediumItalic/CircularStd-MediumItalic.eot');
    src: url('fonts/circularMediumItalic/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/circularMediumItalic/CircularStd-MediumItalic.woff2') format('woff2'),
        url('fonts/circularMediumItalic/CircularStd-MediumItalic.woff') format('woff'),
        url('fonts/circularMediumItalic/CircularStd-MediumItalic.ttf') format('truetype'),
        url('fonts/circularMediumItalic/CircularStd-MediumItalic.svg#CircularStd-MediumItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

*{
    margin: 0; 
    padding: 0; 
    border: 0; 
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Crinsom';
    font-weight: bold;
    position: relative;
}

html,
body {
    background-color: #fff;
    font-family: 'circularBook';
    height: 100%;
    color: #000;
    font-size: 15px;
}

main{
    background-color: #fff;
    position: relative;
}

#messenger{
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 1000;
}

#messenger img{
    width: 50px;
}

*{
    transition: all .3s linear;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus{
    text-decoration: none;
}
a:hover{
    color: #B5A976;
}

.clear{
    clear:both;
}

input, textarea, select{
    border: 1px solid #d9d9d9;
    width: 100%;
    padding: 10px;
}

::-webkit-input-placeholder { /* Edge */
    color: #666;
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #666;
}
  
::placeholder {
    color: #666;
}

#main-header{
    background-color: #000;
    color: #fff;
    padding: 15px 0;
}

#main-header .container{
    display: table;
}

#main-header .container #logo,
#main-header .container #main-menu{
    display: table-cell;
    vertical-align: middle;
    width: auto;
}

#logo img{
    width: 60px;
}

#main-menu{
    text-align: right;
}

#main-menu ul{
    margin: 0;
}

#main-menu li{
    display: inline-block;
    list-style: none;
}

#main-menu li a{
    color: #fff;
    font-family: 'circularBook';
    font-weight: bold;
    display: block;
    padding: 0 10px;
}

#main-menu li button{
    background-color: transparent;
}

#main-menu li a.active{
    color: #f4e49f;
}

#main-menu .apps .opciones-app{
    display: none;
    position: absolute;
    z-index: 1;
}

#main-menu .apps .opciones-app .wrapper{
    margin: 20px 10px;
    background-color: #f5f5f5;
    padding: 20px 20%;
}

#main-menu .apps:hover .opciones-app{
    display: block;
}

#main-menu .apps .opciones-app .ios-app,
#main-menu .apps .opciones-app .android-app{
    float: left;
    text-align: center;
    width: 50%;
}

#main-menu .apps .opciones-app .ios-app img,
#main-menu .apps .opciones-app .android-app img{
    margin: 10px auto;
    display: block;
}

#main-container{
    padding: 50px 0;
}

.btn-co{
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 20px;
}

.btn-co:hover{
    background-color: #fff;
    color: #000
}

.container{
    position: relative;
    width: 80%;
}

#nueva-publicacion{
    border: 2px solid #000;
    color: #000;
    display: inline-block;
    font-family: 'circularBook';
    border-radius: 20px;
    background-color: #f5f5f5;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: -40px;
    float: right;
}

.field-container{
    margin-bottom: 10px;
}

.field-values .option{
    border: 2px solid #000;
    color: #000;
    display: inline-block;
    margin: 10px;
    border-radius: 20px;
    background-color:#f5f5f5;
    padding: 5px 25px;
}

.field-values .option.vacio{
    border-color: #f7d956;
}

.field-values .option:hover,
.field-values .option.selected{
    background-color: #000;
    color: #fff;
}

#portada-block{
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    max-width: 500px;
    width: 100%;
}

#portada-block a{
    border: 2px dashed #d9d9d9;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    width: 98%;
    color: #b3b3b3;
    background-image: url('../img/img.png');
    background-repeat: no-repeat;
    background-position: 99% center;
    background-size: auto 70%;
}

#portada-block.preview,
#portada-block.preview #seleccionar-portada{
    display: inline-block;
    width: auto;
}

#portada-block.preview{
    float: left;
    width: 30%;
}

#portada-block.preview #seleccionar-portada{
    width: 95%;
}

#portada-block.preview #seleccionar-portada #preview-portada{
    max-height: none;
    width: 100%;
}

#campo-portada{
    display: none;
}

#image-header,
#preview-header{
    float: left;
    margin: 0 5%;
    width: 60%;
}

#select-header,
#preview-header img{
    width: 100%;
}

#preview-header img{
    margin-top: 10px;
}

#preview-header{
    text-align: center;
}

#actualizar-header{
    margin: 10px auto;
}

input.genero,
input.categoria{
    display: none;
}

#preview-portada{
    max-height: 150px;
    width: auto;
}

#field-portada,
#field-precio{
    float: left;
    width: 50%;
}

#field-precio select{
    margin-top: 10px;
}

#guardar-publicacion{
    float: right;
    font-weight: bold;
}

.separador{
    background-image: url('../img/virgulilla.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px 0;
    margin: 50px 0;
}

#listado-capitulos{
    border: 1px solid #d9d9d9;
    float: right;
    min-height: 300px;
    width: 30%;
    margin-top: 45px;
}

#listado-capitulos .titulo{
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
    text-transform: capitalize;
}

#agrupador{
    font-size: 13px;
    width: 90%;
    margin: 10px auto;
    display: block;
    padding: 5px 10px;
}

#agrupador::-webkit-input-placeholder {
    font-size: 13px;
}
  
#agrupador:-ms-input-placeholder {
    font-size: 13px;
}
  
#agrupador::placeholder {
    font-size: 13px;
}

#agregar-agrupador{
    margin: 10px auto;
    display: block;
    width: 70%;
    font-size: 13px;
    max-width: 160px;
}

#textos-capitulos{
    float: left;
    width: 69%;
}

.note-editor .note-toolbar{
    background-color: #f5f5f5;
}

.nombre-agrupador{
    font-weight: bold;
    font-size: 25px;
    font-family: 'Crinsom';
    margin-bottom: 10px;
}

.opcion-agrupador{
    border-bottom: 1px solid #d9d9d9;
}

.opcion-agrupador{
    transition: none;
}

#agrupadores .ui-state-highlight{
    height: 40px;
    background-color:#f5f5f5;
  }

.nombre-agrupador-lista button{
    background-color: transparent;
    display: inline-block;
    padding: 5px;
    color:#000;
    text-align: left;
}

.eliminar-capitulo,.editar-nombre{
    text-align: center !important;
}

.nombre-agrupador-lista button span{
    float: right;
}

.seleccionar-agrupador{
    float: none;
    width: 80%;
    overflow: hidden;
}

.novela .seleccionar-agrupador span,
.novela .campo-editar-costo{
    display: none;
}

.novela .campo-editar-nombre{
    width: 90%;
}

.editar-nombre{
    float: right;
    text-align: center;
    width: 10%;
}

.eliminar-capitulo{
    float: right;
    text-align: center;
    width: 10%;
}

.eliminar-capitulo img{
    height: 15px;
    width: auto !important;
}

.editar-agrupador-lista{
    background-color:#f5f5f5;
}

.campo-editar-nombre{
    padding: 4px 5px;
    color:#999;
    float: left;
    width: 90%;
    border: 0;
    background-color: transparent;
}

.campo-editar-costo{
    padding: 4px 5px;
    color:#999;
    float: left;
    width: 22%;
    background-color: transparent;
    border: 0;
}

.guardar-nombre{
    color: #000;
    display: inline-block;
    padding: 5px;
    width: 10%;
}

.opcion-agrupador img{
    width: 15px;
}

#acciones-publicacion{
    margin-bottom: 50px;
    float: right;
    width: 30%;
}

#guardar-capitulos{
    color: #000;
    border-color:  #000;
    background-color: #f5f5f5;
    float: left;
    margin-top: 20px;
    width: 58%;
}

.btn#confirmar-publicar{
    display: block;
    width: 40%;
    margin-top: 20px;
    float: right;
}

table{
    margin: 25px 0;
    width: 100%;
}

table .centrado{
    text-align: center;
}

table th{
    background-color: #000;
    color: #fff;
    padding: 10px 10px;
    font-weight: bold;
}

table td{
    padding: 10px 10px;
    border-bottom: 1px solid #d9d9d9;
}

table tr:hover a{
    color: #B5A976;
}

table a{
    color: inherit;
}

/* #listado-publicaciones #nombre-publicacion{
    width: 50%;
} */

#listado-publicaciones a{
    display: block;
}

#datos-publicacion{
    /* background-color: #f5f5f5; */
    padding: 80px 0;
    position: relative;
}

#datos-publicacion .container{
    position: relative;
}

#datos-publicacion h3{
    margin-bottom: 20px;
}

#publicacion-form .vacio{
    border-color: #f7d956;
}

#datos-publicacion #portada-publicacion{
    float: left;
    text-align: center;
    width: 15%;
}

#sin-categoria,
#sin-genero{
    color: #f7d956;
    display: none;
}

#datos-publicacion #portada-publicacion img{
    width: 90%;
}

#informacion-publicacion{
    float: right;
    width: 85%;
}

#informacion-publicacion .informacion{
    display: table;
    vertical-align: middle;
    margin: 10px 0;
    width: 100%;;
}

#informacion-publicacion .informacion .campo{
    display: table-cell;
    padding-left: 20px;
    width: 100px;
}

#informacion-publicacion .informacion .valor{
    display: table-cell;
    color: #262626;
}

.full-width #main-container.container{
    width: 100%;
    max-width: none;
    padding: 0;
}

#contenedor-acciones{
    z-index: 2;
    position: absolute;
    right: 0;
}

.full-width #bloque-capitulos{
    padding-bottom: 50px;
}

#mensaje-capitulos{
    background-color: #f5f5f5;
    padding: 10px 15px;
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    margin-top: 20px;
    font-size: 14px;
    display: none;
}

#informacion-personal,
#informacion-pago{
    color: #666;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 70%;
}

#informacion-personal h2,
#informacion-pago h2{
    color: #000;
}

#informacion-personal label,
#informacion-pago label{
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.perfil-editar{
    font-family: 'circularBlack';
    float: right;
}


#informacion-personal .form-group label,
#informacion-pago .form-group label{
    margin: 0;
}

#informacion-personal .form-group span,
#informacion-pago .form-group span{
    font-style: italic;
    display: block;
}

#informacion-pago .form-group label span{
    display: inline;
}

#informacion-personal .form-group input,
#informacion-personal .form-group select,
#informacion-personal .form-group span,
#informacion-pago .form-group input,
#informacion-pago .form-group select,
#informacion-pago .form-group span{
    margin-top: 10px;
}

#ap-perfil,
#am-perfil{
    float: left;
    width: 49%;
}

#ap-perfil{
    margin-right: 1%;
}

#am-perfil{
    margin-left: 1%;
}

#dia-perfil,
#mes-perfil,
#anio-perfil{
    float: left;
    width: 32.6666666%;
}

#mes-perfil{
    margin: 0 1%;
}

#pais-perfil,
#estado-perfil,
#municipio-perfil{
    float: left;
    width: 32.6666666%;
}

#estado-perfil{
    margin: 0 1%;
}

#guardar-perfil{
    float: right;
}

#leyenda-transferencia{
    margin-bottom: 10px;
}

#leyenda-transferencia span{
    display: block;
    font-style: italic;
    font-size: 13px;
}

#guardar-pago{
    float: right;
}

#informacion-personal input.vacio,
#informacion-personal select.vacio,
#informacion-pago input.vacio,
#informacion-pago select.vacio{
    border: 1px solid #f7d956;
}

#nuevo-autor .control-group{
    margin-bottom: 10px;
}

#preview-capitulos{
    clear: both;
    position: relative;
    text-align: justify;
    padding: 50px 0;
}

#preview-capitulos .container{
    position: relative;
}

#editar-capitulos{
    /* position: absolute;
    right: 0;
    top: -60px; */
    float: right;
}

#confirmar-publicacion,
#confirmacion-guardado,
#confirmacion-eliminar,
#eliminar-obra-popup{
    background: white;
    padding: 20px 30px;
    text-align: left;
    max-width: 700px;
    margin: 40px auto;
    position: relative;
    text-align: center;
    border: 5px solid #f5f5f5;
}

#confirmar-publicacion div,
#confirmacion-guardado div,
#eliminar-obra-popup div{
    margin-bottom: 50px;
}

#continuar-eliminar,
#aceptar-eliminar{
    max-width: 200px;
    margin: 10px auto;
    display: block;
}

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;

    -webkit-transition: all 0.2s ease-in-out; 
    -moz-transition: all 0.2s ease-in-out; 
    -o-transition: all 0.2s ease-in-out; 
    transition: all 0.2s ease-in-out; 



    -webkit-transform: scale(0.8); 
    -moz-transform: scale(0.8); 
    -ms-transform: scale(0.8); 
    -o-transform: scale(0.8); 
    transform: scale(0.8); 
  }

  /* animate in */
  .my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;

    -webkit-transform: scale(1); 
    -moz-transform: scale(1); 
    -ms-transform: scale(1); 
    -o-transform: scale(1); 
    transform: scale(1); 
  }

  /* animate out */
  .my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8); 
    -moz-transform: scale(0.8); 
    -ms-transform: scale(0.8); 
    -o-transform: scale(0.8); 
    transform: scale(0.8); 

    opacity: 0;
  }

  /* Dark overlay, start state */
  .my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out; 
    -moz-transition: opacity 0.3s ease-out; 
    -o-transition: opacity 0.3s ease-out; 
    transition: opacity 0.3s ease-out;
  }
  /* animate in */
  .my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
  }
  /* animate out */
  .my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
  }

#publicar{
    display: block;
    max-width: 200px;
    margin: 10px auto;
}

.mfp-bg{
    background-color: rgba(255,255,255,.9);
}

#ver-preview{
    display: block;
    margin: 10px auto;
    width: 150px;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

#spinner-guardar{
    float: right;
    margin: 0px;
}

#listado-generos{
    margin: 0 auto;
    max-width: 400px;
}

.btn.editar-genero{
    max-width: 150px;
}

#colecciones .coleccion{
    margin-bottom: 30px;
}

.agregar-coleccion{
    color: #666;
}

.libros-coleccion .libro{
    float: left;
    position: relative;
    width: 20%;
}

.libros-coleccion .libro .quitar{
    right: 0;
    position: absolute;
    top: 0;
    display: inline-block;
    padding: 5px 10px;
    background-color: #000;
    color: #fff;
}

.libros-coleccion .libro .portada{
    width: 90%;
    margin: 0 auto;
    display: block;
}

.libros-coleccion .libro .titulo{
    display: block;
    text-align: center;
    margin-top: 10px;
}

.listado-libro_coleccion{
    display: none;
}

.listado-libro_coleccion .opcion-libro{
    display: inline-block;
    padding: 10px 10px;
}

.listado-libro_coleccion input.libro-agregar{
    display: inline;
    width: auto;
}

.listado-libro_coleccion .agregar-libro{
    margin-bottom: 20px;
}

#creditos-portada{
    margin-top: 10px;
}

/* Ventas */

.publicaciones-ventas{
    margin: 0;
}

.publicaciones-ventas a{
    display: block;
}

.ventas-coleccion{
    margin-bottom: 50px;
}

.historial-ventas span{
    display: block;
    font-size: 13px;
}

#listado-usuarios span{
    display: block;
}

#datos-usuario #nombre-usuario{
    font-weight: bold;
    font-size: 20px;
}

#compras-usuario{
    margin-top: 30px;
}

#bloque-legal{
    margin-bottom: 30px;
}

.bloque-publicaciones{
    display: flex;
    margin-bottom: 50px;
    width: 100%;
}

.bloque-contenido{
    padding: 0 10px;
}

.bloque-publicaciones tr a{
    display: block;
}

#manuscritos,
#cursando,
#bloque-ventas{
    width: 70%;
}

#manuscritos h2{
    width: 250px;
}

#mensajes-editor,
#cursos-talleres{
    text-align: left;
    width: 30%;
}

.btn-mensaje{
    text-align: left;
}
.btn-mensaje .sin-leer{
    background-color: #B22222;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    height: 20px;
    text-align: center;
    width: 20px;
    margin-right: 10px;
}

button.transparente{
    background-color: transparent;
}

button.block{
    display: block;
    width: 100%;
}

a.ver-mas{
    color: #000;
    float: right;
    text-decoration: underline;
    font-style: italic;
}

#sin-capitulos{
    font-size: 20px;
    text-align: center;
}

#row-total td{
    border-bottom: 0;
}

#row-total th{
    text-align: center;
    font-weight: bold;
}

.MuiDialog-paper .titulo-dialogo,
.MuiDialog-paper .contenido-dialogo{
    padding: 20px;
}

.MuiDialog-paper .titulo-dialogo{
    position: relative;
}

.MuiIconButton-root{
    padding: 20px !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
}

#grafica-ventas{
    margin-top: 50px;
}

#recomendaciones-comerciales{
    margin-top: 50px;
}

#bloque-mkt{
    width: 30%;
}

#metas-comerciales .slick-slider{
    margin-top: 30px;
    width: 100%;
}

.slick-dots li{
    height: 10px;
    margin: 0;
    width: 10px;
}

.slick-dots li button{
    padding: 0;
}

.slide-meta .titulo-meta{
    padding: 0 10%;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
}

#metas-comerciales{
    text-align: center;
}

#metas-comerciales .CircularProgressbar{
    display: block;
    margin: 0 auto;
    padding: 10px 0;
    width: 50%;
}

.CircularProgressbar .CircularProgressbar-path{
    stroke: #f8e694;
}

#metas-comerciales div.slick-slider.slick-initialized div.slick-list div.slick-track div.slick-slide.slick-active.slick-current div div.slide-meta div div div{
    margin-top: 0 !important;
    top: 0;
}

.slide-meta .texto-unidades{
}

.slide-meta .texto-unidades span{
    display: block;
}

.slide-meta .numero-vendidas{
    font-size: 20px;
    font-weight: bold;
}

.nivel-libro .nivel{
    font-weight: bold;
    font-size: 18px;
}

.nivel-libro .nivel span{
    display: block;
    font-size: 16px;
}

.nivel-libro .descripcion-nivel{
    margin: 0 auto;
    width: 80%;
}

#reportes, #planes-mkt{
    margin-top: 80px;
    text-align: center;
}

#main-spinner{
    height: 108px;
    width: 140px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-top: 15vh;
}

#main-spinner svg{
    fill:transparent;
    stroke-miterlimit:10;
    stroke: #000;
    position: relative;
    transform: scale(1.05);
}

#main-spinner .fondo{
    animation: dash 1s linear infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background-color: #000;
}

#main-spinner.spinner-small{
    width: 70px;
    height: 55px;
    margin-top: 0;
}

@keyframes dash {
    to {
        left: 0;
    }
}

.main-spinner,
.main-spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.main-spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(0, 0, 0, 1);
  border-right: 5px solid rgba(0, 0, 0, 1);
  border-bottom: 5px solid rgba(0, 0, 0, 1);
  border-left: 5px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


#registro{
    background-color: #fff;
    min-height: 100%;
    height: auto;
    position: relative;
}

#titulo-registro{
    margin-bottom: 10px;
    line-height: 0.9em;
}

#medio-circulo{
    position: absolute;
    width: 9%;
    left: 0;
    top: 17%;
}

#leyendo{
    position: absolute;
    width: 25%;
    right: 0;
    top: 17%;
}

#formato-registro{
    width: 75%;
}

#formato-registro .subtitulo{
    font-size: 20px;
}

#formato-registro form{
    margin-top: 50px;
}

#formato-registro form input,
#formato-registro form select{
    background-color: #f5f5f5;
}

.agrupador-registro{
    background-color: #ecda9a;
    display: inline-block;
    font-family: 'Crinsom';
    font-size: 25px;
    font-weight: bold;
    padding: 0 10px;
    margin-bottom: 20px;
}

.password-wrapper.confirmacion{
    margin-top: 10px;
}

#no-match{
    color: #000;
    background-color: #fdebeb;
    border-color: #d9aeb0;
    margin-top: 20px;
}

.password-wrapper{
    position: relative;
}

.password-wrapper button{
    background-color: transparent;
    position: absolute;
    right: 5px;
    top: 10px;
}

.password-wrapper button img{
    width: 30px;
}

.form-group span{
    font-style: italic;
    display: block;
    margin-bottom: 10px;
}

#nombre-perfil{
    margin-bottom: 10px;
}

#calle-perfil{
    float: left;
    margin-top: 10px;
    width: 58%;
}

#exterior-perfil,
#interior-perfil{
    float: left;
    margin-left: 1%;
    margin-top: 10px;
    width: 20%;
}

#btn-registrar{
    float: right;
}

#mensaje-registro{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    padding-top: 20vh;
}

#mensaje-registro .btn{
    margin-top: 30px;
}

.input-red{
    margin-bottom: 10px;
}

.ayuda{
    background-color: #f5e49c;
    font-family: 'circularBlack';
    font-size: 16px;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 10px;
    line-height: 23px;
}

.ril__toolbar{
    display: none;
}

.ril__image{
    height: 100%;
}

.ril__outer{
    background-color: rgba(0, 0, 0, 0.7);
}

#last-saved{
    margin-top: 20px;
    font-style: italic;
}

.MuiBackdrop-root{
    background-color: rgba(255,255,255,.8) !important;
}

.MuiDialog-paperWidthMd{
    border-radius: 0 !important;
    border: 5px solid #f5f5f5;
    max-width: 750px !important;
    width: 70%;
}

#descripcion-categoria{
    margin: 10px 0 30px;
}

#texto-confirmacion{
    padding: 30px 0;
    text-align: center;
}

#botones-confirmacion{
    margin-top: 50px;
}

.sin-contenido{
    margin: 25px 0;
}

.pasos-edicion .ril__image {
    height: 80%;
}

.red-social{
    margin: 10px 0;
}

.icono-social{
    display: inline-block;
    width: 30px;
}

#enviar-respuesta{
    display: block;
    margin: 20px auto 0;
}

.mensaje-historial{
    display: block;
    font-size: 13px;
    margin: 20px auto;
    width: 100%;
}

.mensaje-historial.admin{
    text-align: left;
    text-align: left;
}

.mensaje-historial.autor{
    float: right;
    text-align: right;
}

#respuesta-mensaje{
    text-align: left;
}

#responder-mensaje{
    margin: 20px auto 0;
    display: block;
    width: 200px;
}

.datos-mensaje .fecha-mensaje{
    color: #999;
    font-style: italic;
}

.datos-mensaje .rol-usuario{
    font-style: italic;
}

.datos-mensaje .rol-usuario.admin{
    color: #85c7e1;
    font-weight: bold;
}

.datos-mensaje .rol-usuario.autor{
    color: #b99ad5;
    font-weight: bold;
}

#mensajes-ventas{
    margin-top: 30px;
    text-align: left;
}