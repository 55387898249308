@font-face {
    font-family: 'Google Sans';
    src: url('fonts/GoogleSans-Regular.eot');
    src: url('fonts/GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/GoogleSans-Regular.woff2') format('woff2'),
        url('fonts/GoogleSans-Regular.woff') format('woff'),
        url('fonts/GoogleSans-Regular.ttf') format('truetype'),
        url('fonts/GoogleSans-Regular.svg#GoogleSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('fonts/GoogleSans-Bold.eot');
    src: url('fonts/GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/GoogleSans-Bold.woff2') format('woff2'),
        url('fonts/GoogleSans-Bold.woff') format('woff'),
        url('fonts/GoogleSans-Bold.ttf') format('truetype'),
        url('fonts/GoogleSans-Bold.svg#GoogleSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

*{ 
    margin: 0; 
    padding: 0; 
    border: 0; 
}

html,
body {
    font-family: 'Google Sans';
    height: 100%;
    color: #000;
}

main{
    background-color: #000;
    height: 100%;
}

#login-wrapper {
    background-color: #000;
    height: 100vh;
}

#login-wrapper .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contenedor-login{
    width: 90%;
    max-width: 307px;
}

#contenedor-login form{
    width: 100%;
}

#logo{
    display: block;
    margin: 0 auto 35px;
    max-width: 150px;
    width: 100%;
}

input.form-control{
    border-radius: 0;
    border: 0;
    color: #333;
    display: block;
    margin-bottom: 10px;
    padding: 20px 15px;
    width: 100%;
    font-size: 17px;
}

input.form-control::-webkit-input-placeholder { /* Edge */
    color: #999;
    font-size: 17px;
}
  
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999;
    font-size: 17px;
}
  
input.form-control::placeholder {
    color: #999;
    font-size: 17px;
}

.btn-primary{
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    /* margin-top: 20px; */
}

.btn-primary:hover{
    border: 1px solid #fff;
    background-color: #000;
}

#sign-in{
    margin-bottom: 30px;
    font-size: 15px;
}

#sign-in a{
    color: #5FC1CB;
    text-decoration: underline;
}

#sign-in a:hover{
    color: #48509d;
}

#forgot{
    display: table;
    margin-top: 50px;
    width: 100%;
}

#forgot div{
    display: table-cell;
    width: 50%;
    text-align: center;
}

#forgot div a{
    color: #5FC1CB;
    text-decoration: underline;
    font-size: 13px;
}

#forgot div a:hover{
    color: #48509d;
}

#invalid-login{
    text-align: center;
    color: #f74931;
}

#login-wrapper .spinner {
  margin: 20px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

#login-wrapper .spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

#login-wrapper .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

#login-wrapper .spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

#login-wrapper .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

#login-wrapper .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.opcion-usuario{
    background-color: transparent;
    color: #fff;
    margin: 10px 0;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 14px;
}

.contenedor-enlace{
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
}

.contenedor-enlace a{
    color: #B5A976;
    display: inline;
    font-size: 16px;
    /* font-weight: bold; */
}

#btn-recuperar{
    background-color: transparent;
    color: #B5A976;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}